import React from 'react';
import { BrowserRouter, Route, RouteComponentProps } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppRoutes from './shared/routes/routes';
import theme from './themes/default';

const baseHref = '/';

interface IAppProps {
  // TODO
}

interface IAppState {
  isLoading: boolean;
}

class App extends React.Component<IAppProps, IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  renderApp = (appProps: RouteComponentProps<any>) => {
    const { isLoading } = this.state;
    const notAllowedPathname: string[] = ['/register', '/'];
    const checkIfIsAuthenticated = !notAllowedPathname.includes(appProps.location.pathname);
    return (
      <div className={'App'}>
        <Route path={'/'} component={() => <AppRoutes {...appProps} isAuthenticated={checkIfIsAuthenticated} />} />
      </div>
    );
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className={'page-container'}>
          <BrowserRouter basename={baseHref}>
            <Route render={this.renderApp} />
          </BrowserRouter>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
