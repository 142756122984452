import beforeLogin from 'features/landing-page/beforeLogin';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from 'shared/error/page-not-found';
const AppRoutes = ({ match, isAuthenticated }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={beforeLogin} />
      <Route path={'*'} component={PageNotFound} />
    </Switch>
  );
};

export default AppRoutes;
