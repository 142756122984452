import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import Theme from 'themes/default';
import App from './App';
import i18n from './config/i18n';
import * as serviceWorker from './serviceWorker';

const initialState = window.INITIAL_REDUX_STATE;
const rootEl = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <I18nextProvider i18n={i18n}>
      <App />
      {/* If this slows down the app in dev disable it and enable when required  */}
    </I18nextProvider>
  </ThemeProvider>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
