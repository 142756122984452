import React, { Fragment, useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import AuthUtils from 'shared/util/auth-utils';

interface IPageNotFoundProps extends RouteComponentProps {
  // TODO
}

const PageNotFound = (props: IPageNotFoundProps) => {
  useEffect(() => {
    props.history.replace(AuthUtils.getMainPath());
  }, []);
  return (
    <Fragment>
      <div>
        <span>Invalid Page</span>
      </div>
    </Fragment>
  );
};

export default withRouter(PageNotFound);
