import { AUTHORITIES } from 'config/constants';

class AuthUtils {
  static AUTH_TOKEN_KEY = 'jhi-authenticationToken';

  static isAuthenticated = () => {
    return localStorage.getItem(AuthUtils.AUTH_TOKEN_KEY) !== null;
  };

  static isUser = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.USER);
  };

  static isAdmin = () => {
    if (!AuthUtils.isAuthenticated()) return false;
    // @ts-ignore
    return jwt_decode(AuthUtils.getToken()!).auth.includes(AUTHORITIES.ADMIN);
  };

  static getMainPath = () => {
    return AuthUtils.isAdmin() ? '/admin/my-files/documents' : AuthUtils.isUser() ? '/home' : '/';
  };
}

export default AuthUtils;
