import { StyledButton } from 'components/styled-button/StyledButton';
import { useTranslation } from 'react-i18next';
import { Background, LoginContainer, LogoImage } from './StyledComponents';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import redExclamation from '../../images/frisia/redexclamation.jpg';

export const WhiteContainer = styled.div`
  z-index: 3;
  background: white;
  border-radius: 8px;
  padding: 64px 84px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 522px;
  height: 460px;
  z-index: 4;
  top: 276px;
  left: 232px;
  background: var(--surface) 0% 0% no-repeat padding-box;
  background: #fcfcfc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #1d1d1d33;
  border-radius: 20px;
  opacity: 1;
`;

export const StyledWarning = styled.div`
  margin-top: 25px;
  display: flex;
  font-size: 22px;
  position: absolute;
  max-width: 354px;
  color: ${({ theme }) => theme.color.frisiaPrimary};
`;

const closedWhiteStyle = {
  transition: 'top 0.3s, left 0.3s, width 0.3s, height 0.3s, transform 0.3s',
};

const beforeLogin = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Background>
      <LoginContainer style={{ justifyContent: 'space-evenly' }}>
        <WhiteContainer>
          <div id="box" style={closedWhiteStyle}>
            <LogoImage style={closedWhiteStyle} />
            <div style={{ marginTop: '100px' }} />
            <StyledButton
              color="frisiaPrimary"
              onClick={() => (window.location.href = 'https://antigo.frisia.coop.br/')}
              style={{ minWidth: '350px', backgroundColor: '#D0CE4F' }}
            >
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                {t('login.oldVersion')}
                <h1>➜</h1>
              </span>
            </StyledButton>
            <StyledWarning style={{ marginTop: '25px', display: 'flex', fontSize: '22px' }}>
              <img src={redExclamation} style={{ width: '70px' }} />
              <div>{t('login.deactivate')}</div>
            </StyledWarning>
          </div>
        </WhiteContainer>
        <WhiteContainer>
          <div id="box" style={closedWhiteStyle}>
            <LogoImage style={closedWhiteStyle} />
            <div style={{ marginTop: '100px' }} />

            <StyledButton
              color="frisiaPrimary"
              onClick={() => (window.location.href = 'https://cooperado.frisia.coop.br/')}
              style={{ minWidth: '350px' }}
            >
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                {t('login.newPortal')}
                <h1>➜</h1>
              </span>
            </StyledButton>
          </div>
        </WhiteContainer>
      </LoginContainer>
    </Background>
  );
};

export default beforeLogin;
